import React from 'react';
import logo from '../assets/images/clippy-clip-black.svg';

const Header = () => {
    return (
        <>
            <header className="header flex justify-center lg:pt-8 pt-4 pb-8 lg:pb-16">
                <a href="/">
                    <img
                        src={logo}
                        className="lg:w-[200px] lg:max-w-[200px] w-[100%] max-w-[200px]"
                        alt="ClippyClip logo"
                    />
                </a>
            </header>
        </>
    );
};

export default Header;
