import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const WebSocketComponent = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let clientId = localStorage.getItem('clientId');
        if (!clientId) {
            clientId = uuidv4();
            localStorage.setItem('clientId', clientId);
        }

        // const ws = new WebSocket(`ws://localhost:8000?clientId=${clientId}`);
        const ws = new WebSocket(`wss://clippy-clip-220b02b0f32f.herokuapp.com?clientId=${clientId}`);
        console.log(`Connecting WebSocket with clientId: ${clientId}`);

        ws.onopen = () => {
            console.log('WebSocket connection established');
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.progress !== undefined) {
                console.log(`Progress: ${data.progress.progress}%, stage: ${data.progress.stage}`);
                setProgress(data.progress);
            }
        };

        ws.onclose = (event) => {
            console.log(`WebSocket connection closed: Code=${event.code}, Reason=${event.reason || 'No reason provided'}`);
        };

        ws.onerror = (err) => {
            console.error('WebSocket error:', err);
        };

        return () => {
            console.log('Cleaning up WebSocket connection');
            ws.close();
        };
    }, []);

    return (
        <div>
            <div
                className='w-full h-[40px] bg-[#ccc] relative rounded duration-200'
            >
                <div
                    style={{
                        width: `${progress.progress}%`
                    }}
                    className='duration-200 h-full bg-black text-center leading-[30px] text-white rounded flex items-center justify-center'
                >
                    {progress.progress}%
                </div>
                <h1>{progress.stage}</h1>
            </div>
        </div>
    );
};


export default WebSocketComponent;
