import Header from "../components/Header";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="terms px-8 lg:px-20 py-10">
        <h1 className="font-medium text-[24px] mb-6">Terms of Service</h1>
        <ol className="list-decimal list-inside space-y-6">
          <li>
            <b>Introduction</b>
            <p>
              Welcome to ClippyClip! By accessing or using our service, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our website.
            </p>
          </li>
          <li>
            <b>Description of Service</b>
            <p>
              ClippyClip provides a tool that allows users to trim and download parts of YouTube videos or audio for personal use. By using this service, you acknowledge that you are responsible for ensuring compliance with any applicable copyright laws and YouTube’s terms of service.
            </p>
          </li>
          <li>
            <b>Acceptable Use</b>
            <p>You agree to use ClippyClip responsibly and comply with the following:</p>
            <ul className="list-disc list-inside pl-4 space-y-2">
              <li>Do not use the service to download copyrighted content without proper authorization.</li>
              <li>Do not use the service for commercial purposes or distribution of downloaded content.</li>
              <li>Do not attempt to bypass any restrictions, protections, or abuse the service.</li>
            </ul>
          </li>
          <li>
            <b>User Responsibilities</b>
            <p>You are solely responsible for:</p>
            <ul className="list-disc list-inside pl-4 space-y-2">
              <li>Ensuring that your use of the service complies with all applicable laws and regulations.</li>
              <li>The content you choose to download, trim, or otherwise process through the service.</li>
            </ul>
          </li>
          <li>
            <b>Intellectual Property</b>
            <p>
              ClippyClip does not claim ownership of any content downloaded or processed through the service. However, you acknowledge that the service itself, including its design, functionality, and logo, is the intellectual property of ClippyClip.
            </p>
          </li>
          <li>
            <b>Limitation of Liability</b>
            <p>
              ClippyClip is provided "as is" without any warranties, express or implied. We are not responsible for:
            </p>
            <ul className="list-disc list-inside pl-4 space-y-2">
              <li>Any misuse of the service by users.</li>
              <li>Issues related to content ownership or copyright violations.</li>
              <li>Any interruptions, bugs, or errors in the service.</li>
            </ul>
          </li>
          <li>
            <b>Privacy</b>
            <p>
              ClippyClip respects your privacy. We do not keep any of your data, neither do we keep and of the videos you download.
            </p>
          </li>
          <li>
            <b>Termination</b>
            <p>
              We reserve the right to suspend or terminate access to ClippyClip at any time for violations of these Terms or other lawful reasons.
            </p>
          </li>
          <li>
            <b>Changes to the Terms</b>
            <p>
              We may update these Terms from time to time. Any changes will be posted on this page with the updated "Effective Date." Continued use of the service after changes are posted constitutes acceptance of the new Terms.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

export default Terms;
