import React from 'react';
import kofi from '../assets/images/kofi.png';

const Footer = () => {
    return (
        <>
            <footer>
                <div className='text-center py-8 pt-16'>
                    <p>I run this project without ads, so if you wish to support me you can buy me a coffee below :)</p>
                    <div className='flex justify-center items-center pt-5'>
                        <a target='_blank' href="https://ko-fi.com/clippyclip" rel="noopener noreferrer">
                            <img
                                className='w-[100px] hover:scale-110 duration-200'
                                src={kofi}
                                alt="Ko-fi"
                            />
                        </a>
                    </div>
                    <br />
                    <p>From <strong>ClippyClip</strong> with ❤️</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;
