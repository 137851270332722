export const processVideo = async (videoId, startTime, endTime, audio) => {
  try {
    const clientId = localStorage.getItem("clientId");
    if (!clientId) {
      console.error('Client ID not found in localStorage');
      return null;
    }

    // const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/process-video', {
    const response = await fetch('https://clippy-clip-220b02b0f32f.herokuapp.com/process-video', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-client-id': clientId,
      },
      body: JSON.stringify({ videoId, startTime, endTime, audio }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error from server:', errorData);
      return null;
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    const fileExtension = audio ? 'mp3' : 'mp4';
    a.download = `${videoId}_trimmed.${fileExtension}`;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    console.log('File downloaded successfully!');
  } catch (error) {
    console.error('An error occurred:', error);
  }
};


export const estimateTime = async (videoId, audio) => {
  try {
    const response = await fetch('localhost:8000/estimate-time', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ videoId, audio }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error from server:', errorData);
      return null;
    }

    const estimateData = await response.json();
    console.log('Estimate Data:', estimateData);
    return estimateData;
  } catch (error) {
    console.error('An error occurred while estimating time:', error);
    return null;
  }
};
