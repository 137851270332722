import React, { useState, useEffect } from 'react';
import { processVideo, estimateTime } from '../components/functions';
import { Link } from 'react-router-dom';
import WebSocketComponent from './WebSocket';

const VideoPlayer = ({ videoId }) => {
    const [player, setPlayer] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [audioOnly, setAudioOnly] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState(false);

    useEffect(() => {
        const loadYouTubeAPI = () => {
            if (!window.YT) {
                const script = document.createElement('script');
                script.src = 'https://www.youtube.com/iframe_api';
                script.async = true;
                document.body.appendChild(script);
            }
        };

        loadYouTubeAPI();

        window.onYouTubeIframeAPIReady = () => {
            if (videoId && !player) {
                const newPlayer = new window.YT.Player('youtube-player', {
                    videoId,
                    events: {
                        onReady: (event) => setPlayer(event.target),
                    },
                });
                setPlayer(newPlayer);
            }
        };
    }, [videoId, player]);

    const handleSetStartTime = () => {
        if (player) {
            const currentTime = player.getCurrentTime();
            setStartTime(formatTime(currentTime));
        }
    };

    const handleSetEndTime = () => {
        if (player) {
            const currentTime = player.getCurrentTime();
            setEndTime(formatTime(currentTime));
        }
    };

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${hrs.toString().padStart(2, '0')}:${mins
            .toString()
            .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const handleDownload = async () => {
        setLoading(true);
        const estimate = await estimateTime(videoId, audioOnly);
        if (estimate) {
            console.log(`Estimated Time: ${estimate.estimatedTime}`);
            setEstimatedTime(estimate.estimatedTotalTime)
        }
        await processVideo(videoId, startTime, endTime, audioOnly);
        setLoading(false);
    };

    return (
        <div className="video-player mt-10 flex flex-col items-center justify-center">
            <div className="video-wrapper flex items-center justify-center">
                <div id="youtube-player" className="youtube-iframe"></div>
            </div>
            <div className="time-controls mt-4 flex flex-wrap items-center justify-center gap-4">
                <div className="time-inputs flex items-center gap-2">
                    <div className='flex gap-2 items-center'>
                        <label>Start time:</label>
                        <input
                            type="text"
                            value={startTime}
                            readOnly
                            className="border-[1px] border-gray-400 px-2 py-1 rounded w-[100px] md:w-[unset]"
                        />
                    </div>
                    <button
                        onClick={handleSetStartTime}
                        className="bg-black text-white px-4 py-1 rounded hover:bg-gray-800 duration-200"
                    >
                        Set start time
                    </button>
                </div>
                <div className="time-inputs flex items-center gap-2 flex-row-reverse md:flex-row">
                    <button
                        onClick={handleSetEndTime}
                        className="bg-black text-white px-4 py-1 rounded hover:bg-gray-800 duration-200"
                    >
                        Set end time
                    </button>
                    <div className='flex gap-2 items-center'>
                        <label>End time:</label>
                        <input
                            type="text"
                            value={endTime}
                            readOnly
                            className="border-[1px] border-gray-400 px-2 py-1 rounded w-[100px] md:w-[unset]"
                        />
                    </div>
                </div>
            </div>
            <div class="container my-6 flex justify-end gap-2 items-center">
                <span>Audio only </span>
                <label class="switch">
                    <input type="checkbox" onChange={(e) => setAudioOnly(e.target.checked)} />
                    <span class="slider"></span>
                </label>
            </div>
            <div className="download-button w-full">
                {!loading &&
                    <>
                        <button
                            className="bg-black text-white w-full px-4 py-2 rounded hover:bg-gray-800 duration-200 disabled:bg-gray-500 disabled:cursor-not-allowed"
                            onClick={handleDownload}
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Start download'}
                        </button>
                        <p className='text-sm'>
                            By downloading the video, you agree to our{' '}
                            <Link
                                className="underline hover:no-underline"
                                to="/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms
                            </Link>
                        </p>
                    </>
                }
                {loading && <WebSocketComponent />}
            </div>

        </div>
    );
};

export default VideoPlayer;
