import React, { useState } from 'react';
import VideoPlayer from '../components/VideoPlayer';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Home = () => {
  const [videoLink, setVideoLink] = useState('');
  const [videoId, setVideoId] = useState('');

  const handleInputChange = (e) => {
    const link = e.target.value;
    setVideoLink(link);

    const match = link.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*v=([^&]+)/) ||
      link.match(/(?:https?:\/\/)?youtu\.be\/([^?]+)/);
    if (match && match[1]) {
      setVideoId(match[1]);
    } else {
      setVideoId('');
    }
  };

  const handlePasteClick = async () => {
    try {
      if (navigator.clipboard) {
        const text = await navigator.clipboard.readText();
        setVideoLink(text);
      } else {
        alert('Clipboard access is not supported in your browser.');
      }
    } catch (error) {
      console.error('Failed to paste from clipboard:', error);
      alert('Failed to paste the link. Please try manually pasting it.');
    }
  };

  return (
    <>
      <div className='flex flex-col justify-between px-[20px] min-h-screen'>
        <Header />  
        <main className="body">
          <div className="main-area flex flex-col items-center justify-center">
            <div className="w-full lg:w-[700px]">
              <label className="block mb-2 font-medium text-left">
                Paste the YouTube video link here:
              </label>
              <div className="input-field flex justify-center border-black border-[2px] w-full p-2">
                <input
                  className="w-full outline-none"
                  placeholder="Paste your YouTube link here"
                  value={videoLink}
                  onChange={handleInputChange}
                />
                {/* <img
                  src={paste}
                  className="h-[26px] opacity-50 px-2 hover:cursor-pointer hover:opacity-100 duration-200"
                  alt="Paste Icon"
                  onClick={handlePasteClick}
                /> */}
              </div>
            </div>
            {videoId && <VideoPlayer videoId={videoId} />}
            <div className="description text-center pt-20">
              <h1 className="font-semibold lg:text-[40px] text-[24px]">
                Youtube clipper/trimmer
              </h1>
              <p className="max-w-[650px] pt-10">
                Got a YouTube video but only need that one part? Paste the link above, simply choose your start and end points in the video preview and let ClippyClip clip it for you. Also, if you only need the audio, just switch the audio only toggle. It's as quick and easy as that :)
              </p>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;
